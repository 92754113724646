export default [

    {
        title: 'My Shifts',
        route: 'shifts',
        icon: 'CalendarIcon',
    },

    {
        title: 'Invoice',
        route: 'invoice',
        icon: 'FileTextIcon',
    },
    {
        title: 'My Locations',
        route: 'locations',
        icon: 'MapPinIcon',
    },
    {
        title: 'My Documents',
        route: 'documents',
        icon: 'FileTextIcon',
    },


]
