<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>




    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 ">

      <h3 class=" ml-1 mb-0">{{$route.meta.title}}</h3>
<!--      <dark-Toggler class="d-none d-lg-block" />-->
    </div>



          <!-- Staff Bank Leaves -->

<!--
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex ">

          <b-alert
              :show="true"
              class="mb-0  rounded"
              variant="primary"
          >
            <div class="alert-body text-dark">
                          <feather-icon
                                class=" text-primary"
                                icon="CheckCircleIcon"
                                size="18"
                            />
             <span class="ml-1">Leave on Mar 13 - 14, 2021 was approved! </span>
             <span class="ml-5"/> <span class="ml-5">
               <feather-icon
            class="ml-5 cursor-pointer"
            icon="XIcon"
            size="16"
        />
             </span>
            </div>
          </b-alert>

    </div> -->
<!--    Invitations-->
<!--    <div class="demo-inline-spacing  bg-transparent mt-n2 ">-->
<!--    <b-button-->
<!--        badge="4"-->
<!--        badge-classes="badge-info"-->
<!--        size="sm"-->
<!--        variant="primary"-->
<!--        @click="showInvitations()"-->
<!--    >Invitations <span class="text-white">({{invitations.length}})</span></b-button>-->
<!--&lt;!&ndash;      <b-badge pill variant="success">Success</b-badge>&ndash;&gt;-->
<!--&lt;!&ndash;      <feather-icon&ndash;&gt;-->
<!--&lt;!&ndash;          icon="BellIcon"&ndash;&gt;-->
<!--&lt;!&ndash;          size="21"&ndash;&gt;-->
<!--&lt;!&ndash;          class="text-info"&ndash;&gt;-->
<!--&lt;!&ndash;          badge="4"&ndash;&gt;-->
<!--&lt;!&ndash;          badge-classes="badge-info"&ndash;&gt;-->
<!--&lt;!&ndash;      />&ndash;&gt;-->
<!--    </div>-->

    <!-- Message Icon -->

<!--    <div class="demo-inline-spacing  bg-transparent mt-n2 ">-->
<!--      <feather-icon-->
<!--        icon="MessageSquareIcon"-->
<!--        size="18"-->
<!--        class="text-dark"-->
<!--        @click="$router.push('/chat')"-->
<!--      />-->
<!--    </div>-->

    <!-- BellIcon -->

<!--    <div class="demo-inline-spacing  bg-transparent mt-n1 ">-->
<!--     <NotificationIcon/>-->
<!--    </div>-->



    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <attendance-dropdown />-->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
<!--            <p
              v-if="currentUser"
              class="user-name font-weight-bolder mb-0"
            >
              {{ currentUser.user.name }}

            </p>-->
            <p
                class="user-name  mb-0 font-weight-700"
            >
<!--             <span v-if="currentUser !==null">{{currentUser.user.first_name}} {{currentUser.user.last_name}}</span>-->
              <span v-if="currentUser" >{{currentUser.first_name +' '+ currentUser.last_name}}</span>

            </p>
            <span class="user-status">Online</span>
<!--            <span class="user-status">Clinical Pharmacist</span>-->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/1.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center"  @click="profileAccountChange()">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="localLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BAlert,
  BRow,
  BCol,
  BInputGroup,
  BButton,
  BBadge
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapActions, mapGetters } from 'vuex'
import NotificationIcon from './NotificationDropdown'
import invitations from "@/apis/modules/invitations";
// import AttendanceDropdown from './AttendanceDropdown.vue'
import profile from "@/apis/modules/profile";

import { loginAppUrl } from '@/constants/config';

export default {
  components: {
    BBadge,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    NotificationIcon,
    BAlert,
    BRow,
    BCol,
    BInputGroup,
    BButton,

    // Navbar Components
    DarkToggler,
    // AttendanceDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data(){
    return{
    }
  },
  computed: {
    ...mapGetters(['currentUser']),

  },
  mounted() {
    this.getAuthUser()
  },
  methods: {
    profileAccountChange(){
      window.location.href = loginAppUrl;
    },
    async getAuthUser(){
      try {
        const response = await profile.getCurrentUser()
        this.user = response.data.data
        this.setCurrentUser(this.user)

      }catch (error){
        this.convertAndNotifyError(error)
      }
    },
    ...mapActions(['logout','setCurrentUser']),
    async localLogout() {
      await this.logout()
      //this.$router.push({ name: 'login' })
      window.location.href = loginAppUrl;
    },
    // showInvitations(){
    //   this.$router.push('/invitations')
    // }
  },

}
</script>
